import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api/request-response';
import {
  AuthenticateRequest,
  CheckIfUserExistsRequest,
  CheckIfUserExistsResponse,
  GetResetDataResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignUpRequest,
  SignUpResponse,
  UserData,
  LogoutResponse,
} from 'services/api/auth/auth.types';

export class AuthApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/auth');
  }

  async authenticate(
    body: AuthenticateRequest,
  ): Promise<RequestResponse<UserData>> {
    const response: ApiResponse<UserData> = await this.api.post(
      '/user/authenticate',
      body,
    );
    return this.handleResponse<UserData>(response);
  }

  async logout(): Promise<RequestResponse<LogoutResponse>> {
    const response: ApiResponse<LogoutResponse> = await this.api.post(
      '/user/logout',
    );
    return this.handleResponse<LogoutResponse>(response);
  }

  async checkIfUserExists(
    body: CheckIfUserExistsRequest,
  ): Promise<RequestResponse<CheckIfUserExistsResponse>> {
    const response: ApiResponse<CheckIfUserExistsResponse> = await this.api.post(
      '/auth/check',
      body,
    );
    return this.handleResponse<CheckIfUserExistsResponse>(response);
  }

  async requestPasswordReset(
    body: ResetPasswordRequest,
  ): Promise<RequestResponse<ResetPasswordResponse>> {
    const response: ApiResponse<ResetPasswordResponse> = await this.api.post(
      '/auth/forgot',
      body,
    );

    return this.handleResponse<ResetPasswordResponse>(response);
  }

  async signUp(body: SignUpRequest): Promise<RequestResponse<SignUpResponse>> {
    const response: ApiResponse<SignUpResponse> = await this.api.post(
      '/users',
      body,
    );
    return this.handleResponse<SignUpResponse>(response);
  }

  async getLogged(): Promise<RequestResponse<UserData>> {
    const response: ApiResponse<UserData> = await this.api.get('/user');
    return this.handleResponse<UserData>(response);
  }

  async getResetData(
    token: string,
  ): Promise<RequestResponse<GetResetDataResponse>> {
    const response: ApiResponse<GetResetDataResponse> = await this.api.get(
      '/auth/reset',
      {
        token,
      },
    );
    return this.handleResponse<GetResetDataResponse>(response);
  }

  async resetPassword(
    body: ResetPasswordRequest,
  ): Promise<RequestResponse<UserData>> {
    const response: ApiResponse<UserData> = await this.api.post(
      '/auth/reset',
      body,
    );
    return this.handleResponse<UserData>(response);
  }
}
