import { ApiResponse, ApisauceInstance } from 'apisauce';
import { getGeneralApiProblem } from 'services/api/api-problem';
import { RequestResponse } from 'services/api/request-response';

export class ApiInstance {
  protected api: ApisauceInstance;
  protected path: string;

  constructor(apiInstance: ApisauceInstance, path: string) {
    this.api = apiInstance;
    this.path = path;
  }

  async getSingle<T>(id: string): Promise<RequestResponse<T>> {
    const response: ApiResponse<T> = await this.api.get(`${this.path}/${id}`);
    return this.handleResponse<T>(response);
  }

  async getAll<T>(): Promise<RequestResponse<T>> {
    const response: ApiResponse<T> = await this.api.get(`${this.path}`);
    return this.handleResponse<T>(response);
  }

  async put<T>(data: any, path: string = null): Promise<RequestResponse<T>> {
    const response: ApiResponse<T> = await this.api.put(
      `${path || this.path}`,
      data,
    );
    return this.handleResponse<T>(response);
  }

  protected async handleResponse<T>(
    response: ApiResponse<T>,
  ): Promise<RequestResponse<T>> {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        return {
          error: Object.assign({}, problem, {
            message: (response.data && response.data['error']) ?? '',
          }),
          data: response?.data,
          success: false,
        };
      } else {
        return {
          error: { kind: 'cancelled', message: 'Cancelled' },
          data: {} as T,
          success: false,
        };
      }
    }
    return { error: null, data: response.data, success: true };
  }
}
