import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api/request-response';
import { GiftCardDesign, GiftCardRecord } from 'types/gift-card.type';

export class GiftCardApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/gift-cards');
  }

  async getGiftCardDesigns(): Promise<RequestResponse<GiftCardDesign[]>> {
    const response: ApiResponse<GiftCardDesign[]> = await this.api.get(
      '/gift-cards/designs',
    );
    return this.handleResponse<GiftCardDesign[]>(response);
  }

  async getGiftCard(hash: string): Promise<RequestResponse<GiftCardRecord>> {
    const response: ApiResponse<GiftCardRecord> = await this.api.get(
      `/gift-cards/redeem/${hash}`,
    );
    return this.handleResponse<GiftCardRecord>(response);
  }

  async redeem(hash: string): Promise<RequestResponse<GiftCardRecord>> {
    const response: ApiResponse<GiftCardRecord> = await this.api.post(
      '/gift-cards/redeem',
      { hash },
    );
    return this.handleResponse<GiftCardRecord>(response);
  }
}
