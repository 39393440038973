import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api/request-response';
import {
  CartUpdateRequest,
  GetShippingMethodsResponse,
  ValidateZipResponse,
} from 'services/api/cart/cart-api.types';
import { CheckoutShipping } from 'modules/shop/checkout/checkout.types';
import { UserResponse } from 'services/api/user/user-api.types';
import { AxiosRequestConfig } from 'axios';

export class CartApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/user/cart');
  }

  async update(
    request: CartUpdateRequest,
    config?: AxiosRequestConfig,
  ): Promise<RequestResponse<UserResponse>> {
    const response: ApiResponse<UserResponse> = await this.api.put(
      '/user/cart',
      request,
      config,
    );
    return this.handleResponse<UserResponse>(response);
  }

  async getShippingMethods(): Promise<
    RequestResponse<GetShippingMethodsResponse>
  > {
    const response: ApiResponse<GetShippingMethodsResponse> = await this.api.post(
      '/lib/shipping',
    );

    return this.handleResponse<GetShippingMethodsResponse>(response);
  }

  async validateZip(
    data: CheckoutShipping,
  ): Promise<RequestResponse<ValidateZipResponse>> {
    const response: ApiResponse<ValidateZipResponse> = await this.api.post(
      '/address/validate-zip',
      data,
    );

    return this.handleResponse<ValidateZipResponse>(response);
  }
}
