import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api/request-response';
import {
  FetchLeadTimeResponse,
  GetBestSellersResponse,
  GetSkusResponse,
  SearchAutocompleteResponse,
  SearchResponse,
} from 'services/api';

import cache from 'memory-cache';

export class ProductApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/products');
  }

  async getSkus(
    cacheResponse: boolean = true,
  ): Promise<RequestResponse<GetSkusResponse>> {
    const temporal = cache.get('skus');

    if (!cacheResponse || !temporal) {
      const response: ApiResponse<GetSkusResponse> = await this.api.get(
        '/products/skus',
      );

      if (cacheResponse) {
        cache.put('skus', response, 300000);
      }

      return this.handleResponse<GetSkusResponse>(response);
    }

    return this.handleResponse<GetSkusResponse>(temporal);
  }

  async getBestSellers(): Promise<RequestResponse<GetBestSellersResponse>> {
    const response: ApiResponse<GetBestSellersResponse> = await this.api.get(
      '/products/best-sellers',
    );
    return this.handleResponse<GetBestSellersResponse>(response);
  }

  async fetchLeadTime(): Promise<RequestResponse<FetchLeadTimeResponse>> {
    const response = await this.api.get<FetchLeadTimeResponse>(
      '/products/lead-time',
    );
    return this.handleResponse<FetchLeadTimeResponse>(response);
  }

  async search(value: string): Promise<RequestResponse<SearchResponse>> {
    const response = await this.api.get<SearchResponse>(`/search/${value}`);
    return this.handleResponse<SearchResponse>(response);
  }

  async searchAutocomplete(
    value: string,
  ): Promise<RequestResponse<SearchAutocompleteResponse>> {
    const response = await this.api.get<SearchAutocompleteResponse>(
      `/search/autocomplete/${value}`,
    );
    return this.handleResponse<SearchAutocompleteResponse>(response);
  }
}
