import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api';
import {
  AddAddressResponse,
  AddDesignerToFavoritesRequest,
  DeletePaymentResponse,
  EmailQuizRequest,
  EmailQuizResponse,
  FetchCartResponse,
  FetchTaxesRequest,
  FetchTaxesResponse,
  GetReferralInfoResponse,
  ReferralRequest,
  ReferralResponse,
  RegisterForSkuAvailabilityNotificationsRequest,
  UpdateHeardAboutUsRequest,
  UpdateHeardAboutUsResponse,
  UpdateWishlistRequest,
  UpdateWishlistResponse,
  VerifyGiftCodeRequest,
  VerifyGiftCodeResponse,
} from 'services/api/user/user-api.types';
import { Address, Payment } from 'types/user.type';
import { UserData } from 'services/api/auth';

export class UserApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/user');
  }

  async fetchTaxes(
    request: FetchTaxesRequest,
  ): Promise<RequestResponse<FetchTaxesResponse>> {
    const response = await this.api.post<FetchTaxesResponse>(
      '/lib/tax',
      request,
    );
    return this.handleResponse<FetchTaxesResponse>(response);
  }

  async fetchCart(): Promise<RequestResponse<FetchCartResponse>> {
    const response = await this.api.get<FetchCartResponse>('/user/user-cart');
    return this.handleResponse<FetchCartResponse>(response);
  }

  async updateWishlist(
    request: UpdateWishlistRequest,
  ): Promise<RequestResponse<UpdateWishlistResponse>> {
    const response = await this.api.put<UpdateWishlistResponse>(
      '/user/wishlist',
      request,
    );
    return this.handleResponse<UpdateWishlistResponse>(response);
  }

  async updateHeardAboutUs(
    request: UpdateHeardAboutUsRequest,
  ): Promise<RequestResponse<UpdateHeardAboutUsResponse>> {
    const response = await this.api.put<UpdateHeardAboutUsResponse>(
      '/user/about-us',
      request,
    );
    return this.handleResponse<UpdateHeardAboutUsResponse>(response);
  }

  async addAddress(
    request: Address,
  ): Promise<RequestResponse<AddAddressResponse>> {
    const response = await this.api.post<AddAddressResponse>(
      '/address/create',
      request,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async updateAddress(
    request: Address,
  ): Promise<RequestResponse<AddAddressResponse>> {
    const response = await this.api.put<AddAddressResponse>(
      '/address/update',
      request,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async removeAddress(
    id: number,
  ): Promise<RequestResponse<AddAddressResponse>> {
    const response = await this.api.delete<AddAddressResponse>(
      `/address/${id}/remove`,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async addPayment(request: Payment): Promise<RequestResponse<UserData>> {
    const response = await this.api.post<UserData>('/payment/create', request);
    return this.handleResponse<UserData>(response);
  }

  async deletePayment(
    paymentId: number,
  ): Promise<RequestResponse<DeletePaymentResponse>> {
    const response = await this.api.delete<DeletePaymentResponse>(
      `/payment/delete/${paymentId}`,
    );
    return this.handleResponse<DeletePaymentResponse>(response);
  }

  async verifyGiftCode(
    request: VerifyGiftCodeRequest,
  ): Promise<RequestResponse<VerifyGiftCodeResponse>> {
    const response = await this.api.post<VerifyGiftCodeResponse>(
      '/user/gifts/verify',
      request,
    );
    return this.handleResponse<VerifyGiftCodeResponse>(response);
  }

  async emailQuizResults(
    request: EmailQuizRequest,
  ): Promise<RequestResponse<EmailQuizResponse>> {
    const response = await this.api.post<EmailQuizResponse>(
      '/user/send-quiz',
      request,
    );
    return this.handleResponse<EmailQuizResponse>(response);
  }

  async refer(
    request: ReferralRequest,
  ): Promise<RequestResponse<ReferralResponse>> {
    const response = await this.api.post<ReferralResponse>(
      '/user/invite-referral',
      request,
    );
    return this.handleResponse<ReferralResponse>(response);
  }

  async getReferralInfo(
    referralCode: string,
  ): Promise<RequestResponse<GetReferralInfoResponse>> {
    const response = await this.api.get<GetReferralInfoResponse>(
      `/check-referral-link/${referralCode}`,
    );
    return this.handleResponse<GetReferralInfoResponse>(response);
  }

  async addDesignerToFavorites(
    request: AddDesignerToFavoritesRequest,
  ): Promise<RequestResponse<UserData>> {
    const response = await this.api.put<UserData>('/user/favorite', request);
    return this.handleResponse<UserData>(response);
  }

  async registerForSkuAvailabilityNotifications(
    request: RegisterForSkuAvailabilityNotificationsRequest,
  ): Promise<RequestResponse<ReferralResponse>> {
    const response = await this.api.post<ReferralResponse>(
      '/user/sku-get-notified',
      request,
    );
    return this.handleResponse<ReferralResponse>(response);
  }
}
