import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { RequestResponse } from 'services/api/request-response';
import {
  AffirmTransactionRequest,
  AppleTransactionRequest,
  GoogleTransactionRequest,
  TransactionRequest,
  TransactionResponse,
} from 'services/api/payment/payment-api.types';
import { Payment } from 'types/user.type';

export class PaymentApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/user/transactions');
  }

  async pay(
    data: TransactionRequest,
  ): Promise<RequestResponse<TransactionResponse>> {
    const response: ApiResponse<TransactionResponse> = await this.api.post(
      '/user/transactions',
      data,
    );

    return this.handleResponse<TransactionResponse>(response);
  }

  async payWithApple(
    data: AppleTransactionRequest,
  ): Promise<RequestResponse<TransactionResponse>> {
    const response: ApiResponse<TransactionResponse> = await this.api.post(
      '/user/transactions/apple',
      data,
    );

    return this.handleResponse<TransactionResponse>(response);
  }

  async payWithGoogle(
    data: GoogleTransactionRequest,
  ): Promise<RequestResponse<TransactionResponse>> {
    const response: ApiResponse<TransactionResponse> = await this.api.post(
      '/user/transactions/google',
      data,
    );

    return this.handleResponse<TransactionResponse>(response);
  }

  async payWithAffirm(
    data: AffirmTransactionRequest,
  ): Promise<RequestResponse<TransactionResponse>> {
    const response: ApiResponse<TransactionResponse> = await this.api.post(
      '/user/transactions',
      data,
    );

    return this.handleResponse<TransactionResponse>(response);
  }

  async saveCard(data: Payment): Promise<RequestResponse<any>> {
    const response: ApiResponse<any> = await this.api.post(
      '/payment/create',
      data,
    );

    return this.handleResponse<any>(response);
  }
}
