import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import { GetInfoResponse, RequestResponse } from 'services/api';
import { UserResponse } from 'services/api/user/user-api.types';
import { DesignerInfo } from 'types/user.type';
import { AxiosRequestConfig } from 'axios';

export class DesignerApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/designers');
  }

  async getInfo(
    profileLink: string,
  ): Promise<RequestResponse<GetInfoResponse>> {
    const response = await this.api.get<GetInfoResponse>(
      `/designers/${profileLink}`,
    );
    return this.handleResponse<GetInfoResponse>(response);
  }

  async update(
    request: DesignerInfo,
    config?: AxiosRequestConfig,
  ): Promise<RequestResponse<UserResponse>> {
    const response = await this.api.put<UserResponse>(
      '/designer',
      request,
      config,
    );
    return this.handleResponse<UserResponse>(response);
  }
}
