import { ApisauceInstance, create } from 'apisauce';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { UserApi } from 'services/api/user/user.api';
import { ProductApi } from 'services/api/product';
import { AuthApi } from 'services/api/auth';
import { DesignerApi } from 'services/api/designer';
import { CollectionApi } from 'services/api/collection';
import { Cookies } from 'react-cookie';
import { CartApi } from 'services/api/cart';
import { PaymentApi } from 'services/api/payment';
import { environment } from 'config/environment';
import { AUTH_TOKEN } from 'constants/common';
import { GiftCardApi } from 'services/api/gitf-card';

export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;

  auth: AuthApi;
  cart: CartApi;
  collection: CollectionApi;
  designer: DesignerApi;
  giftCard: GiftCardApi;
  product: ProductApi;
  payment: PaymentApi;
  user: UserApi;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.setup();

    if (process.browser) {
      if (
        window.location.hostname !== 'www.gantri.com' &&
        environment.API_URL == 'https://api.gantri.com/api'
      ) {
        if (window.location.pathname !== '/404') {
          window.location.href = '/404';
        }

        throw new Error(
          'Production environment variables are been used for a non-production host.',
        );
      }

      const token = new Cookies().get(AUTH_TOKEN);

      if (token) {
        this.registerAuthHeader(token);
      }
    }
  }

  setup(): void {
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });

    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log(environment.HOST_URL);
    console.log(environment.API_URL);
    console.log(environment);
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');
    console.log('-==-=-=-=-=-=-=-=-=-==-=-=--==--=-=-==--=-=');

    this.auth = new AuthApi(this.apisauce);
    this.cart = new CartApi(this.apisauce);
    this.collection = new CollectionApi(this.apisauce);
    this.designer = new DesignerApi(this.apisauce);
    this.giftCard = new GiftCardApi(this.apisauce);
    this.product = new ProductApi(this.apisauce);
    this.payment = new PaymentApi(this.apisauce);
    this.user = new UserApi(this.apisauce);
  }

  registerAuthHeader(token: string): void {
    this.apisauce.setHeader('Authorization', `Bearer ${token}`);
  }

  clearAuthHeader(): void {
    this.apisauce.deleteHeader('Authorization');
  }
}

export const api = new Api();
